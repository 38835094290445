import { StaticCellId } from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

interface ScheduleNotificationModalState {
  isOpen: boolean;
  staticCellId: StaticCellId | null;
}

interface ConditionalNotificationModalState {
  isOpen: boolean;
  staticCellId: StaticCellId | null;
}

type AppViewSliceState = {
  conditionalNotificationModal: ConditionalNotificationModalState;
  scheduleNotificationModal: ScheduleNotificationModalState;
  hasSchedules: boolean;
};

const initialAppViewState: AppViewSliceState = {
  conditionalNotificationModal: {
    isOpen: false,
    staticCellId: null,
  },
  scheduleNotificationModal: {
    isOpen: false,
    staticCellId: null,
  },
  hasSchedules: false,
};

const appViewSlice = createSlice({
  name: "appView",
  initialState: initialAppViewState,
  reducers: {
    openConditionalNotificationModalForCell: (
      state,
      action: PayloadAction<StaticCellId>,
    ) => {
      state.conditionalNotificationModal.isOpen = true;
      state.conditionalNotificationModal.staticCellId = action.payload;
    },
    openConditionalNotificationModal: (state) => {
      state.conditionalNotificationModal.isOpen = true;
      state.conditionalNotificationModal.staticCellId = null;
    },
    closeConditionalNotificationModal(state) {
      state.conditionalNotificationModal.isOpen = false;
      state.conditionalNotificationModal.staticCellId = null;
    },
    openScheduleNotificationModalForCell: (
      state,
      action: PayloadAction<StaticCellId>,
    ) => {
      state.scheduleNotificationModal.isOpen = true;
      state.scheduleNotificationModal.staticCellId = action.payload;
    },
    openScheduleNotificationModal: (state) => {
      state.scheduleNotificationModal.isOpen = true;
    },
    closeScheduleNotificationModal(state) {
      state.scheduleNotificationModal.isOpen = false;
      state.scheduleNotificationModal.staticCellId = null;
    },
    setHasSchedules: (state, action: PayloadAction<boolean>) => {
      state.hasSchedules = action.payload;
    },
  },
});

export const selectConditionalNotificationModalState = (
  state: RootState,
): ConditionalNotificationModalState =>
  state.appView.conditionalNotificationModal;

export const selectNotificationModalState = (
  state: RootState,
): ScheduleNotificationModalState => state.appView.scheduleNotificationModal;

export const selectHasSchedules = (state: RootState): boolean =>
  state.appView.hasSchedules;

export const appViewReducer = appViewSlice.reducer;
export const {
  closeConditionalNotificationModal,
  closeScheduleNotificationModal,
  openConditionalNotificationModal,
  openConditionalNotificationModalForCell,
  openScheduleNotificationModal,
  openScheduleNotificationModalForCell,
  setHasSchedules,
} = appViewSlice.actions;
